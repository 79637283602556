.Banner {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 30px;
}

.Banner__img {
  display: block;
  max-width: auto;
  max-height: auto;
  width: 1490px;
  height: 420px;
}

@media (min-width: 63.9375em) and (max-width: 77.5em) {
  .Banner__img {
    width: 800px;
    height: 440px;
    object-fit: cover;
  }
}

/*Tablet*/
@media (min-width: 46.25em) and (max-width: 63.9375em) {
  .Banner__img {
    width: 600px;
    height: 240px;
    object-fit: cover;
  }
}

/*Mobile*/
@media (max-width: 46.1875em) {
  .Banner__img {
    width: 400px;
    height: 140px;
    object-fit: cover;
  }
}

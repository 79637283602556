.popup__overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 50;
}

.Popup__content {
  width: 30%;
  height: auto;
  border-radius: 5px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(63, 63, 63, 0.31);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
}

.popUpLink {
  color: #396cf7;
  text-decoration: underline;
}

.Content__header {
  font-size: 2rem;
  margin: 10px;
  text-align: center;
  color: white;
  font-weight: 700;
}

.Content__info-pay {
  padding: 0 20px;
  text-align: center;
  color: white;
  font-weight: 700;
}

.confirm {
  background: #396cf7;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 30px;
  margin: 10px;
  cursor: pointer;
}

.confirm:hover {
  background: #629fe4;
}

/*Tablet*/
@media (min-width: 46.25em) and (max-width: 63.9375em) {
  .Content__header {
    font-size: 1.5rem;
  }
  .Content__info-pay {
    font-size: 0.7rem;
  }
  .confirm {
    font-size: 0.8rem;
    padding: 7px 20px;
  }
  #PopUp {
    display: none;
  }
}

/*Mobile*/
@media (max-width: 46.1875em) {
  .Content__header {
    font-size: 1rem;
  }
  .Content__info-pay {
    font-size: 0.5rem;
  }
  .confirm {
    font-size: 0.5rem;
    padding: 5px 15px;
  }
  #PopUp {
    display: none;
  }
}

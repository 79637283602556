.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: position 0.3s ease;
}

.navbar__section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar--scrolled {
  position: fixed;
  background: rgba(0, 0, 0, 0.31);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  z-index: 9999999999;
}

.navbar_group {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.navbar_group h1 {
  transition: 0.3s ease;
  position: relative;
}

.navbar_group h1:hover {
  color: #1e90ff;
  text-shadow: 0px 0px 10px rgba(173, 216, 230, 0.8),
    0px 0px 15px rgba(135, 206, 250, 0.6);
}

.cus_link {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.navbar__logo {
  height: 100px;
}

.navbar__title {
  font-size: 1.2rem;
}

/*Tablet*/
@media (min-width: 46.25em) and (max-width: 63.9375em) {
  /* .navbar {
    padding: 0 40px;
  } */

  .navbar__logo {
    height: 50px;
  }
}

/*Mobile*/
@media (max-width: 46.1875em) {
  /* .navbar {
    padding: 0 10px;
  } */

  .navbar__section {
    flex: 1;
  }

  .navbar__title a {
    font-size: 0.75rem;
  }

  .navbar_group h1 {
    font-size: 0.5rem;
    text-align: center;
  }

  .navbar__logo {
    height: 50px;
  }
}

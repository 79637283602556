.singlePost {
  flex: 9;
  font-family: "Lora", serif;
  color: white;
  background-image: url(../../assets/bgClient.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh; 
  overflow-y: scroll;
}

.singlePostTitle__row{
  width: 50%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
}

.singlePostImg {
  width: 50%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  margin: 0 auto 20px;
}

p span {
  font-weight: 800;
  font-size: 1.5rem;
  font-weight: 700;
}

.singlePostTitle {
  margin-bottom: 10px;
  text-align: center;
  color: #ffff;
}

.singlePostTitle, .singlePostDesc {
  font-size: 1.5rem!important;
  font-weight: 700;
  text-transform: uppercase;
} 

.singlePostTitleInput {
  margin: 10px;
  font-size: 28px;
  text-align: center;
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
  outline: none;
}

.singlePostEdit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 16px;
}

.singlePostEdit i {
  color: white;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: white;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #ffff;
}

.singlePostDesc {
  color: #ffff;
  /* font-size: 18px; */
  line-height: 25px;
  text-align: center;
}

.singlePostTitle span, .singlePostDesc span{
  color: #4ab8d3
}

.delete{
  background: #f53f3f;
}

.btn:hover{
  background: #25b850;
}

.delete:hover{
  background: white;
  color: #f53f3f;
}

.singlePostDescInput {
  border: none;
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDescInput:focus {
  outline: none;
}

.singlePostButton {
  width: 100px;
  border: none;
  background-color: teal;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 20px;
}

.imagePreviewItem {
  display: flex;
  justify-content: center;
  width: 100%;
}

.imagePreviewItem img {
  width: 60%;
  margin: 10px 0;
}

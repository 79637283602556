.post_session {
  position: relative;
  margin-bottom: 100px;
}

.posts {
  display: grid;
  padding: 0 250px;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.item {
  position: relative;
  height: auto;
  width: 100%;
  background: rgba(139, 139, 139, 0.31);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  overflow: hidden;
}

.item img {
  width: 100%;
}

.item__content {
  padding: 10px;
  height: 90px;
  line-height: 15px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  overflow: hidden;
}

.item__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.item__info {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.item__title {
  display: block;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: white;
  margin-bottom: 7px;
}

.item__desc {
  font-size: 1.2rem;
  color: #ff6708;
  font-weight: 700;
  text-transform: uppercase;
}

.mb-5 {
  margin-bottom: 7px;
  color: red;
}

.btn {
  width: 49%;
  background-color: #c61b07;
  color: #fff;
  border-radius: 3px;
  border: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
}

.btn:first-child {
  background: #ff6708;
}

.group__btn {
  display: flex;
  margin: 10px;
  justify-content: space-between;
}

.btn a {
  display: block;
  padding: 10px 10px;
}

.btn:hover {
  background: #25b850;
}

.show-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ff6708;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.show-more-button:hover {
  background-color: #25b850;
}

/*Tablet & mobile*/
@media (min-width: 77.5em) and (max-width: 103.125em) {
  .posts {
    padding: 0 100px;
  }
  .item__title {
    font-size: 0.85rem;
  }

  .item__desc {
    font-size: 1rem;
  }
  .group__btn a {
    font-size: 0.85rem;
  }
}

@media (min-width: 63.9375em) and (max-width: 77.5em) {
  .posts {
    padding: 0 100px;
  }
  .item__title {
    font-size: 0.7rem;
  }

  .item__desc {
    font-size: 0.9rem;
  }
  .group__btn a {
    font-size: 0.6rem;
  }
}

/*Tablet*/
@media (min-width: 46.25em) and (max-width: 63.9375em) {
  .posts {
    padding: 0 100px;
    grid-template-columns: repeat(2, 1fr);
  }
  .item__title {
    font-size: 0.8rem;
  }

  .item__desc {
    font-size: 1.1rem;
  }
}

/*Mobile*/
@media (max-width: 46.1875em) {
  .posts {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 50px 0;
    overflow: hidden;
  }
}

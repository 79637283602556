.home__setBackground {
  background-image: url(../../assets/bgClient.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home {
  display: flex;
}

.home_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 250px;
}

.home_btn button {
  border: 2px solid #ff6708;
  float: left;
  padding: 10px 20px 10px;
  border-radius: 3px;
  margin-right: 10px;
  position: relative;
  margin-bottom: 0px;
  font-weight: bold;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #ff6708;
  background: rgba(0, 0, 0, 0.31);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  z-index: 999999999;
}

.home_btn button:hover {
  background-color: #ff6708;
  color: #fff;
  cursor: pointer;
}

.active {
  background-color: #ff6708 !important;
  color: #fff !important;
}

.news__section {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  margin: 20px 100px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.news__section h2 {
  font-size: 3rem;
  color: #ffd700;
  text-align: center;
  margin-bottom: 20px;
}

.news__section p {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 15px;
}

.news__section strong {
  font-size: 2rem;
  color: #ff4500;
}

.news__section h2,
.news__section p,
.news__section strong {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

#button-contact-vr {
  position: fixed;
  bottom: 88px;
  z-index: 99999;
  right: 8px;
}

#zalo-vr {
  transition: 1s all;
  -moz-transition: 1s all;
  -webkit-transition: 1s all;
}

.button-contact {
  position: relative;
  margin-top: -5px;
}

#button-contact-vr .button-contact .phone-vr {
  position: relative;
  visibility: visible;
  background-color: transparent;
  width: 90px;
  height: 90px;
  cursor: pointer;
  z-index: 11;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition: visibility 0.5s;
  left: 0;
  bottom: 0;
  display: block;
}

.phone-vr-circle-fill {
  width: 65px;
  height: 65px;
  top: 10px;
  left: 10px;
  position: absolute;
  box-shadow: 0 0 0 0 #c31d1d;
  background-color: rgba(230, 8, 8, 0.7);
  border-radius: 50%;
  border: 2px solid transparent;
  -webkit-animation: phone-vr-circle-fill 2.3s infinite ease-in-out;
  animation: phone-vr-circle-fill 2.3s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animuiion: zoom 1.3s infinite;
  animation: zoom 1.3s infinite;
}

.sungtrai {
  position: absolute;
  z-index: 1;
  left: 0vw;
  bottom: -100px;
}

.nvphai {
  position: absolute;
  z-index: 0;
  right: 0vw;
  bottom: -140px;
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

#tiktok-vr .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 #000;
  background-color: rgba(0, 0, 0, 0.7);
}

#tiktok-vr .phone-vr-img-circle {
  background-color: #000000;
}

#fb-vr .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 #002ab4;
  background-color: rgba(0, 42, 180, 0.7);
}

#fb-vr .phone-vr-img-circle {
  background-color: rgb(0, 42, 180);
}

#zalo-vr .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 #2196f3;
  background-color: rgba(33, 150, 243, 0.7);
}

#zalo-vr .phone-vr-img-circle {
  background-color: #2196f3;
}

#messenger-vr .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
}

#messenger-vr .phone-vr-img-circle {
  background-color: #ffffff;
}

.phone-vr-img-circle {
  background-color: #e60808;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 25px;
  left: 25px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  animation: phone-vr-circle-fill 1s infinite ease-in-out;
}

.phone-vr-img-circle img {
  max-height: 25px;
  max-width: 27px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.phone-vr-img-circle a {
  display: block;
  line-height: 37px;
}

@keyframes zoom {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px transparent;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes phone-vr-circle-fill {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}

/*Tablet & mobile*/
@media (min-width: 77.5em) and (max-width: 103.125em) {
  .home_btn {
    margin: 20px 100px;
  }
}

@media (min-width: 63.9375em) and (max-width: 77.5em) {
  .home_btn {
    margin: 20px 100px;
  }
}

/*Tablet*/
@media (min-width: 46.25em) and (max-width: 63.9375em) {
  .home_btn {
    margin: 20px 100px;
  }
  .news__section {
    margin: 20px 50px;
  }

  .news__section h2 {
    font-size: 2.5rem;
  }

  .news__section p {
    font-size: 1.5rem;
  }

  .news__section strong {
    font-size: 1.8rem;
  }
}

/*Mobile*/
@media (max-width: 46.1875em) {
  .home_btn {
    margin: 20px auto 0;
  }
  .news__section {
    margin: 20px 10px;
  }

  .news__section h2 {
    font-size: 1.8rem;
  }

  .news__section p {
    font-size: 1.2rem;
  }

  .news__section strong {
    font-size: 1.4rem;
  }
}
